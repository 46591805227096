export const styles = {
  grantsPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    isolation: "isolate",
    position: "relative",
    width: "100%",
    background: "#222425",
    overflowX: "clip",
  },
  grantsContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "96px calc((100% - 1110px)/2)",
    gap: "64px",
    isolation: "isolate",
    width: "100%",
    position: "relative",
  },
  grantsContentMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "96px 20px",
    gap: "64px",
    isolation: "isolate",
    width: "100%",
    position: "relative",
  },
  grantsTitle: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#FFFFFF",
  },
  grantsItem: {
    display: "inline",
  },
  grantsItemTitle: {
    color: "#ffffff",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "25px",
    lineHeight: "26px",
    paddingBottom: "20px",
  },
  grantsText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    letterSpacing: "0.005em",
    width: "60%",
    textAlign: "justify",
  },
  grantsTextMobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    letterSpacing: "0.005em",
    width: "100%",
  },
  separator: {
    marginTop: "20px",
    width: "100%",
    height: "1px",
    backgroundColor: "#FFFFFF",
    opacity: 0.1,
  },
  imgStyle: {
    float: "right",
    width: "400px",
    backgroundColor: "#FFFFFF",
  } as React.CSSProperties,
  imgStyleMobile: {
    float: "none",
    maxWidth: "40%",
    minWidth: "300px",
    backgroundColor: "#FFFFFF",
    marginBottom: "5px",
  } as React.CSSProperties,
};
