import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import OtherServicesListItem from "./OtherServicesListItem";
import { Box, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";
import { Services } from "../../constants";

const OtherServices = () => {
  const { t } = useTranslation();
  const { service } = useParams();
  const mobileSize = useMediaQuery("(max-width: 1130px)");

  return (
    <Box sx={styles.otherServices}>
      <Box sx={styles.background}>
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.backgroundLight} />
      </Box>
      <Box
        sx={
          mobileSize
            ? styles.otherServicesContentMobile
            : styles.otherServicesContent
        }
      >
        <Typography
          sx={
            mobileSize
              ? styles.otherServicesTitleMobile
              : styles.otherServicesTitle
          }
          variant="h2"
        >
          {t("common.otherServices")}
        </Typography>
        <Box
          sx={
            mobileSize
              ? styles.otherServicesListMobile
              : styles.otherServicesList
          }
        >
          <Box sx={styles.otherServicesListItem}>
            {service === "software" ? (
              <OtherServicesListItem
                service={Services.Hardware}
                color="#00E4BB"
              />
            ) : (
              <OtherServicesListItem
                service={Services.Software}
                color="#7696FF"
              />
            )}
          </Box>
          <img src="/img/add.svg" alt="add" />
          <Box sx={styles.otherServicesListItem}>
            {service === "software" || service === "hardware" ? (
              <OtherServicesListItem
                service={Services.Production}
                color="#FFB800"
              />
            ) : (
              <OtherServicesListItem
                service={Services.Hardware}
                color="#00E4BB"
              />
            )}
          </Box>
          <img src="/img/add.svg" alt="add" />
          <Box sx={styles.otherServicesListItem}>
            {service === "certificate" ? (
              <OtherServicesListItem
                service={Services.Production}
                color="#FFB800"
              />
            ) : (
              <OtherServicesListItem
                service={Services.Certificate}
                color="#40EBF4"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OtherServices;
