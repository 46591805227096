import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Grid, Box, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

interface ServicesGridItemProps {
  service: string;
  color: string;
}

const ServicesGridItem = ({ service, color }: ServicesGridItemProps) => {
  const { t } = useTranslation();
  const mobileSize = useMediaQuery("(max-width: 1130px)");
  return (
    <Grid sx={styles.servicesGridItem} item xs={6} md={3}>
      <Box sx={styles.iconButton}>
        <img
          src={`/img/servicesGrid/${service}Icon.svg`}
          alt={`${service}Icon`}
        />
      </Box>
      <Link
        style={styles.link}
        to={mobileSize ? `/${service}` : `/${service}?id=1`}
      >
        <Typography
          sx={
            mobileSize
              ? { ...styles.gridItemH3mobile, color }
              : { ...styles.gridItemH3, color }
          }
          variant="h3"
        >
          {t(`servicesGrid.${service}.title`)}
        </Typography>
      </Link>
      <Typography sx={styles.gridItemDescription}>
        {t(`servicesGrid.${service}.description`)}
      </Typography>
      <Box sx={styles.gridItemNavBox}>
        <Link style={styles.link} to={`/${service}?id=1`}>
          <Typography
            variant="h5"
            sx={
              mobileSize
                ? styles.gridItemNavLinksMobile
                : styles.gridItemNavLinks
            }
          >
            {t(`servicesGrid.${service}.01`)}
          </Typography>
        </Link>
        <Link style={styles.link} to={`/${service}?id=2`}>
          <Typography
            variant="h5"
            sx={
              mobileSize
                ? styles.gridItemNavLinksMobile
                : styles.gridItemNavLinks
            }
          >
            {t(`servicesGrid.${service}.02`)}
          </Typography>
        </Link>
        <Link style={styles.link} to={`/${service}?id=3`}>
          <Typography
            variant="h5"
            sx={
              mobileSize
                ? styles.gridItemNavLinksMobile
                : styles.gridItemNavLinks
            }
          >
            {t(`servicesGrid.${service}.03`)}
          </Typography>
        </Link>
        <Link style={styles.link} to={`/${service}?id=4`}>
          <Typography
            variant="h5"
            sx={
              mobileSize
                ? styles.gridItemNavLinksMobile
                : styles.gridItemNavLinks
            }
          >
            {t(`servicesGrid.${service}.04`)}
          </Typography>
        </Link>
        <Link style={styles.link} to={`/${service}?id=5`}>
          <Typography
            variant="h5"
            sx={
              mobileSize
                ? styles.gridItemNavLinksMobile
                : styles.gridItemNavLinks
            }
          >
            {t(`servicesGrid.${service}.05`)}
          </Typography>
        </Link>
      </Box>
    </Grid>
  );
};

export default ServicesGridItem;
