import React from "react";
import { Link } from "react-router-dom";

import { Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";
import { useTranslation } from "react-i18next";

interface OtherServicesListItemProps {
  service?: string;
  color: string;
}

const OtherServicesListItem = ({
  service,
  color,
}: OtherServicesListItemProps) => {
  const { t } = useTranslation();
  const mobileSize = useMediaQuery("(max-width: 1130px)");
  return (
    <Link
      style={styles.link}
      to={mobileSize ? `/${service}` : `/${service}?id=1`}
    >
      <Typography sx={{ ...styles.otherServicesListItemText, color }}>
        {t(`${service}.title`)}
      </Typography>
    </Link>
  );
};

export default OtherServicesListItem;
