import React from "react";

export const styles = {
  whoWeAre: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "96px calc((100% - 1110px)/2)",
    gap: "64px",
    isolation: "isolate",
    width: "100%",
    position: "relative",
    scrollMargin: "80px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flexWrap: "wrap",
    padding: "0px",
    gap: "48px",
  },
  whoWeAreTitle: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#FFFFFF",
  },
  whoWeAreTitleMobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "40px",
    color: "#FFFFFF",
  },
  skills: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0px",
    gap: "8px",
    width: "1110px",
  },
  skillBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "24px",
    gap: "8px",
    border: "1px solid #FFFFFF",
  },
  skillBoxText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
  },
  ourStory: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "112px",
    isolation: "isolate",
    position: "relative",
  },
  ourStoryMobile: {
    width: "100%",
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "center",
    padding: "0px",
    gap: "50px",
    isolation: "isolate",
    position: "relative",
  },
  ourStoryTextBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    gap: "10px",
  },
  ourStoryText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#FFFFFF",
    letterSpacing: "0.005em",
  },
  topLine: {
    position: "absolute",
    width: "660px",
    height: "1px",
    left: "calc(50% - 824px/2 + 308px)",
    top: "0px",
    backgroundColor: "#31373E",
  },
  topLineMobile: {
    position: "absolute",
    width: "600px",
    height: "1px",
    left: "calc(50% - 824px/2)",
    top: "0px",
    backgroundColor: "#31373E",
  },
  background: {
    position: "absolute",
    left: "0px",
    top: "0px",
    padding: "96px calc((100% - 1110px)/2)",
    width: "100%",
    height: "908px",
    opacity: "0.1",
    transform: " matrix(1, 0, 0, -1, 0, 0)",
  },
  line2: {
    position: "absolute",
    width: "1px",
    height: "908px",
    left: "calc(50% - 1px/2 - 555.5px)",
    top: "0px",
    background: "#FFFFFF",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
  line6: {
    position: "absolute",
    width: "1px",
    height: "908px",
    left: "calc(50% - 1px/2 - 227.5px)",
    top: "0px",
    background: "#FFFFFF",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
  line3: {
    position: "absolute",
    width: "1px",
    height: "908px",
    left: "calc(50% - 1px/2 + 0.5px)",
    top: "0px",
    background: "#FFFFFF",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
  line8: {
    position: "absolute",
    width: "1px",
    height: "255px",
    left: "calc(50% - 1px/2 - 74.5px)",
    bottom: "104px",
    background: "#F3F6F9",
  },
  doubleArrow: {
    position: "absolute",
    width: "74px",
    left: "calc(50% - 1px/2 - 74.5px)",
    bottom: "114px",
  } as React.CSSProperties,
  line5: {
    position: "absolute",
    width: "1px",
    height: "908px",
    left: "calc(50% - 1px/2 + 278.5px)",
    top: "0px",
    background: "#FFFFFF",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
  line4: {
    position: "absolute",
    width: "1px",
    height: "908px",
    left: "calc(50% - 1px/2 + 555.5px)",
    top: "0px",
    background: "#FFFFFF",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  },
  light: {
    position: "absolute",
    width: "100%",
    height: "1109px",
    left: "0px",
    bottom: "719px",
    background:
      "radial-gradient(56.05% 179.86% at 50.14% 100%, #3759CA 0%, #00B8E0 47.66%, #FFB800 100%)",
    filter: "blur(150px)",
  },
  closeTag: {
    position: "absolute",
    width: "50.19px",
    height: "55.68px",
    left: "calc(50% - 1px/2 + 385.5px)",
    bottom: "134.56px",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  } as React.CSSProperties,
  closeTagMobile: {
    position: "absolute",
    width: "50.19px",
    height: "55.68px",
    left: "calc(50% - 1px/2 + 100.5px)",
    bottom: "44.56px",
    transform: "matrix(1, 0, 0, -1, 0, 0)",
  } as React.CSSProperties,
};
