import React, { useEffect, useState, useRef, useMemo } from "react";
import { ToastContainer, Slide } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useParams, useSearchParams } from "react-router-dom";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import Contact from "../Contact/Contact";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import OtherServices from "../OtherServices/OtherServices";

import { certificateStyles } from "./styles/certificateStyles";
import { hardwareStyles } from "./styles/hardwareStyles";
import { productionStyles } from "./styles/productionStyles";
import { softwareStyles } from "./styles/softwareStyles";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../Sidebar/Sidebar";
import { Services } from "../../constants";
import ServicePageTopContent from "./ServicePageTopContent";
import Badge from "../Badge/Badge";

const ServicePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { service } = useParams();
  const queryParam = searchParams.get("id") || "1";
  const { t } = useTranslation();
  const [styles, setStyles] = useState(softwareStyles);
  const contactRef = useRef<null | HTMLDivElement>(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const mobileSize = useMediaQuery("(max-width: 1130px)");

  const Ref01 = useRef<null | HTMLDivElement>(null);
  const Ref02 = useRef<null | HTMLDivElement>(null);
  const Ref03 = useRef<null | HTMLDivElement>(null);
  const Ref04 = useRef<null | HTMLDivElement>(null);
  const Ref05 = useRef<null | HTMLDivElement>(null);

  const refArray = useMemo(() => {
    return [Ref01, Ref02, Ref03, Ref04, Ref05];
  }, []);

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true);
  };

  const scrollToContact = () => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
      handleCloseSidebar();
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    handleCloseSidebar();

    switch (service) {
      case Services.Software:
        setStyles(softwareStyles);
        break;
      case Services.Hardware:
        setStyles(hardwareStyles);
        break;
      case Services.Production:
        setStyles(productionStyles);
        break;
      case Services.Certificate:
        setStyles(certificateStyles);
        break;
      default:
        break;
    }
  }, [service]);

  useEffect(() => {
    if (mobileSize && queryParam) {
      refArray[parseInt(queryParam) - 1].current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  }, [mobileSize, queryParam, refArray]);

  return (
    <Box sx={styles.servicesPage}>
      <Header
        handleOpenSidebar={handleOpenSidebar}
        scrollToContact={scrollToContact}
        service
      />
      <Sidebar
        scrollToContact={scrollToContact}
        open={isSidebarOpen}
        handleCloseSidebar={handleCloseSidebar}
      />
      <ServicePageTopContent service={service} styles={styles} />
      {mobileSize ? (
        <Box sx={styles.serviceTypesMobile}>
          <Box ref={Ref01} sx={styles.serviceTypesContentMobile}>
            <Box sx={styles.serviceTypesListItemTitleBox}>
              <Typography sx={styles.serviceTypesListItemText}>
                {t(`${service}.01.title`)}
              </Typography>
            </Box>
            <Typography
              sx={styles.serviceTypesDescriptionBold}
              variant="subtitle1"
            >
              {t(`${service}.01.descriptionBold`)}
            </Typography>
            <Typography sx={styles.serviceTypesDescriptionNormal}>
              {t(`${service}.01.descriptionNormal`)}
            </Typography>
            <Button onClick={scrollToContact} sx={styles.interestedButton}>
              <Typography sx={styles.interestedButtonText}>
                {t("common.interested")}
              </Typography>
            </Button>
          </Box>
          <Box ref={Ref02} sx={styles.serviceTypesContentMobileDark}>
            <Box sx={styles.serviceTypesListItemTitleBox}>
              <Typography sx={styles.serviceTypesListItemText}>
                {t(`${service}.02.title`)}
              </Typography>
            </Box>
            <Typography
              sx={styles.serviceTypesDescriptionBold}
              variant="subtitle1"
            >
              {t(`${service}.02.descriptionBold`)}
            </Typography>
            <Typography sx={styles.serviceTypesDescriptionNormal}>
              {t(`${service}.02.descriptionNormal`)}
            </Typography>
            <Button onClick={scrollToContact} sx={styles.interestedButton}>
              <Typography sx={styles.interestedButtonText}>
                {t("common.interested")}
              </Typography>
            </Button>
          </Box>
          <Box ref={Ref03} sx={styles.serviceTypesContentMobile}>
            <Box sx={styles.serviceTypesListItemTitleBox}>
              <Typography sx={styles.serviceTypesListItemText}>
                {t(`${service}.03.title`)}
              </Typography>
            </Box>
            <Typography
              sx={styles.serviceTypesDescriptionBold}
              variant="subtitle1"
            >
              {t(`${service}.03.descriptionBold`)}
            </Typography>
            <Typography sx={styles.serviceTypesDescriptionNormal}>
              {t(`${service}.03.descriptionNormal`)}
            </Typography>
            <Button onClick={scrollToContact} sx={styles.interestedButton}>
              <Typography sx={styles.interestedButtonText}>
                {t("common.interested")}
              </Typography>
            </Button>
          </Box>
          <Box ref={Ref04} sx={styles.serviceTypesContentMobileDark}>
            <Box sx={styles.serviceTypesListItemTitleBox}>
              <Typography sx={styles.serviceTypesListItemText}>
                {t(`${service}.04.title`)}
              </Typography>
            </Box>
            <Typography
              sx={styles.serviceTypesDescriptionBold}
              variant="subtitle1"
            >
              {t(`${service}.04.descriptionBold`)}
            </Typography>
            <Typography sx={styles.serviceTypesDescriptionNormal}>
              {t(`${service}.04.descriptionNormal`)}
            </Typography>
            <Button onClick={scrollToContact} sx={styles.interestedButton}>
              <Typography sx={styles.interestedButtonText}>
                {t("common.interested")}
              </Typography>
            </Button>
          </Box>
          <Box ref={Ref05} sx={styles.serviceTypesContentMobile}>
            <Box sx={styles.serviceTypesListItemTitleBox}>
              <Typography sx={styles.serviceTypesListItemText}>
                {t(`${service}.05.title`)}
              </Typography>
            </Box>
            <Typography
              sx={styles.serviceTypesDescriptionBold}
              variant="subtitle1"
            >
              {t(`${service}.05.descriptionBold`)}
            </Typography>
            <Typography sx={styles.serviceTypesDescriptionNormal}>
              {t(`${service}.05.descriptionNormal`)}
            </Typography>
            <Button onClick={scrollToContact} sx={styles.interestedButton}>
              <Typography sx={styles.interestedButtonText}>
                {t("common.interested")}
              </Typography>
            </Button>
          </Box>
        </Box>
      ) : (
        <Box sx={styles.serviceTypes}>
          <Box sx={{ ...styles.background, top: "0px", opacity: "0.1" }}>
            <Box sx={styles.bgLine2} />
            <Box sx={styles.bgLine2} />
            <Box sx={styles.bgLine2} />
            <Box sx={styles.bgLine2} />
            <Box sx={styles.bgLine2} />
            <Box sx={styles.bgLine2} />
            <img
              style={styles.serviceLogo}
              src={`/img/${service}PageLogos/serviceLogo.svg`}
              alt=""
            />
          </Box>
          <Box sx={styles.serviceTypesContent}>
            <Box sx={styles.serviceTypesList}>
              <Box
                onClick={() => setSearchParams({ id: "1" })}
                sx={
                  queryParam === "1"
                    ? styles.serviceTypesListItemSelected
                    : styles.serviceTypesListItem
                }
              >
                <Typography sx={styles.serviceTypesListItemText} variant="h5">
                  {t(`${service}.01.title`)}
                </Typography>
                {queryParam === "1" ? (
                  <img
                    src={`/img/${service}PageLogos/rightArrow.svg`}
                    alt="rightArrow"
                  />
                ) : null}
              </Box>
              <img src={`/img/${service}PageLogos/add.svg`} alt="add" />
              <Box
                onClick={() => setSearchParams({ id: "2" })}
                sx={
                  queryParam === "2"
                    ? styles.serviceTypesListItemSelected
                    : styles.serviceTypesListItem
                }
              >
                <Typography sx={styles.serviceTypesListItemText} variant="h5">
                  {t(`${service}.02.title`)}
                </Typography>
                {queryParam === "2" ? (
                  <img
                    src={`/img/${service}PageLogos/rightArrow.svg`}
                    alt="rightArrow"
                  />
                ) : null}
              </Box>
              <img src={`/img/${service}PageLogos/add.svg`} alt="add" />
              <Box
                onClick={() => setSearchParams({ id: "3" })}
                sx={
                  queryParam === "3"
                    ? styles.serviceTypesListItemSelected
                    : styles.serviceTypesListItem
                }
              >
                <Typography sx={styles.serviceTypesListItemText} variant="h5">
                  {t(`${service}.03.title`)}
                </Typography>
                {queryParam === "3" ? (
                  <img
                    src={`/img/${service}PageLogos/rightArrow.svg`}
                    alt="rightArrow"
                  />
                ) : null}
              </Box>
              <img src={`/img/${service}PageLogos/add.svg`} alt="add" />
              <Box
                onClick={() => setSearchParams({ id: "4" })}
                sx={
                  queryParam === "4"
                    ? styles.serviceTypesListItemSelected
                    : styles.serviceTypesListItem
                }
              >
                <Typography sx={styles.serviceTypesListItemText} variant="h5">
                  {t(`${service}.04.title`)}
                </Typography>
                {queryParam === "4" ? (
                  <img
                    src={`/img/${service}PageLogos/rightArrow.svg`}
                    alt="rightArrow"
                  />
                ) : null}
              </Box>
              <img src={`/img/${service}PageLogos/add.svg`} alt="add" />
              <Box
                onClick={() => setSearchParams({ id: "5" })}
                sx={
                  queryParam === "5"
                    ? styles.serviceTypesListItemSelected
                    : styles.serviceTypesListItem
                }
              >
                <Typography sx={styles.serviceTypesListItemText} variant="h5">
                  {t(`${service}.05.title`)}
                </Typography>
                {queryParam === "5" ? (
                  <img
                    src={`/img/${service}PageLogos/rightArrow.svg`}
                    alt="rightArrow"
                  />
                ) : null}
              </Box>
            </Box>
            <Box sx={styles.serviceTypesDescription}>
              <Typography sx={styles.serviceTypesDescriptionTitle} variant="h3">
                {t(`${service}.0${queryParam}.title`)}
              </Typography>
              <Typography
                sx={styles.serviceTypesDescriptionBold}
                variant="subtitle1"
              >
                {t(`${service}.0${queryParam}.descriptionBold`)}
              </Typography>
              <Typography sx={styles.serviceTypesDescriptionNormal}>
                {t(`${service}.0${queryParam}.descriptionNormal`)}
              </Typography>
              <Button onClick={scrollToContact} sx={styles.interestedButton}>
                <Typography sx={styles.interestedButtonText}>
                  {t("common.interested")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      <OtherServices />
      <Contact
        ref={contactRef}
        bgColor={styles.contact.backgroundColor}
        textColor={styles.contact.textColor}
        buttonColor={styles.contact.buttonColor}
      />
      <Footer scrollToContact={scrollToContact} service />
      <Badge />
      <ToastContainer transition={Slide} />
    </Box>
  );
};

export default ServicePage;
