import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";

import references from "./references.json";
import { Box, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Testimonials = () => {
  const { t } = useTranslation();
  const mobileSize = useMediaQuery("(max-width: 1130px)");

  return (
    <Box sx={styles.testimonials}>
      <Box sx={styles.testimonialsBackground}>
        <Box sx={styles.bgEllipse1} />
        <Box sx={styles.bgEllipse2} />
        <Box sx={styles.bgEllipse3} />
      </Box>

      <Box sx={styles.testimonialsTitle}>
        <Typography
          sx={mobileSize ? styles.testimonialsH2mobile : styles.testimonialsH2}
          variant="h2"
        >
          {t("testimonials.title")}
        </Typography>
        <Typography sx={styles.testimonialsH5} variant="h5">
          {t("testimonials.subTitle")}
        </Typography>
      </Box>
      <Box sx={styles.review}>
        <Box sx={styles.quoteMarks}>
          <img
            src="/img/quoteMarks/topQuoteMark.svg"
            style={styles.topQuoteMark}
            alt="topQuoteMark"
          />
          <img
            src="/img/quoteMarks/bottomQuoteMark.svg"
            style={styles.bottomQuoteMark}
            alt="bottomQuoteMark"
          />
        </Box>
        <Carousel
          showThumbs={false}
          showStatus={false}
          showArrows={false}
          showIndicators={false}
          autoPlay
          infiniteLoop
          interval={5000}
          width={mobileSize ? 340 : 540}
          swipeable={false}
          stopOnHover={false}
        >
          {references.map((reference) => {
            return (
              <Box key={reference.name}>
                <Typography sx={styles.reviewText}>{reference.text}</Typography>
                <Box sx={styles.reviewer}>
                  <Box sx={styles.nameTitle}>
                    <Typography
                      variant="h3"
                      sx={mobileSize ? styles.nameMobile : styles.name}
                    >
                      {reference.name}
                    </Typography>
                    <Typography
                      sx={mobileSize ? styles.titleMobile : styles.title}
                    >
                      {reference.title}
                    </Typography>
                  </Box>
                  <a target="_blank" rel="noreferrer" href={reference.url}>
                    <img
                      style={
                        mobileSize
                          ? styles.reviewerLogoMobile
                          : styles.reviewerLogo
                      }
                      src={reference.logoPath}
                      alt="logo"
                    />
                  </a>
                </Box>
              </Box>
            );
          })}
        </Carousel>
      </Box>
    </Box>
  );
};

export default Testimonials;
