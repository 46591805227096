import React from "react";

import { Box, Typography } from "@mui/material";

import { styles } from "./styles";

interface GrantItemProps {
  title: string;
  text: string;
  imgPath: string;
  mobileSize: boolean;
}

const GrantItem = ({ title, text, imgPath, mobileSize }: GrantItemProps) => {
  return (
    <>
      <Box sx={styles.grantsItem}>
        <Typography sx={styles.grantsItemTitle} variant="h3">
          {title}
        </Typography>
        <img
          style={mobileSize ? styles.imgStyleMobile : styles.imgStyle}
          src={imgPath}
          alt="grantLogo"
        />
        <Typography sx={mobileSize ? styles.grantsTextMobile : styles.grantsText} dangerouslySetInnerHTML={{__html: text}}/>
      </Box>
      <Box sx={styles.separator} />
    </>
  );
};

export default GrantItem;
