import React from "react";
import { Link } from "react-router-dom";

import { Box, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

const Badge = () => {
  const mobileSize = useMediaQuery("(max-width: 1130px)");

  return (
    <Box sx={mobileSize ? styles.badgeContainerMobile : styles.badgeContainer}>
      <Link to="/grants">
        <img
          style={mobileSize ? styles.badgeLogoMobile : styles.badgeLogo}
          src="/img/grantsLogo/grantsLogo.png"
          alt="grantsLogo"
        />
      </Link>
    </Box>
  );
};

export default Badge;
