export const styles = {
  contact: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "96px calc((100% - 1110px)/2)",
    gap: "48px",
    isolation: "isolate",
    width: "100%",
    minHeight: "412px",
    position: "relative",
    scrollMargin: "80px",
  },
  partnersBackground: {
    position: "absolute",
    width: "100%",
    height: "592px",
    left: "0px",
    top: "calc(50% - 592px/2 + 27px)",
    opacity: "0.15",
    overflow: "hidden",
  },
  bgTextProduct: {
    position: "absolute",
    left: "0px",
    top: "42px",
  } as React.CSSProperties,
  bgTextEngineering: {
    position: "absolute",
    right: "0px",
    top: "268.51px",
  } as React.CSSProperties,
  contactTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0px",
    gap: "8px",
    zIndex: 3,
  },
  contactH2: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "56px",
  },
  contactH4: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "25px",
    lineHeight: "26px",
  },
  form: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    padding: "0px",
    gap: "8px",
    width: "100%",
    color: "#FFFFFF",
  },
  formMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    gap: "20px",
    width: "100%",
    color: "#FFFFFF",
  },
  formField: {
    flexGrow: "1",
    color: "inherit",
    "& .MuiInputBase-root": {
      border: "2px solid #FFFFFF",
      borderRadius: "6px",
    },
    "& .MuiInputLabel-root": {
      color: "inherit",
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
    },
    "& .MuiInputLabel-shrink": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "0px",
    },
  },
  helperText: {
    color: "#FFFFFF",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
  },
  submitButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px 0px 20px",
    gap: "10px",
    backgroundColor: "#FFB800",
    borderRadius: "6px",
    minWidth: "180px",
    height: "60px",
    "&:hover": {
      backgroundColor: "#C9930A",
    },
  },
  disabledButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px 0px 20px",
    gap: "10px",
    backgroundColor: "#5C676D",
    borderRadius: "6px",
    minWidth: "180px",
    height: "60px",
  },
  submitButtonText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#222425",
  },
  disabledSubmitButtonText: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    color: "#5C676D",
  },
};
