export const styles = {
  landingPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    isolation: "isolate",
    position: "relative",
    width: "100%",
    background: "#222425",
    overflowX: "clip",
  },
  heroServices: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px calc((100% - 1107px)/2)",
    isolation: "isolate",
    width: "100%",
    background: "#222425",
    position: "relative",
  },
  background: {
    position: "absolute",
    left: "-1px",
    right: "0px",
    top: "0px",
    bottom: "0px",
    width: "100%",
    height: "100%",
  },
  backgroundCircle: {
    position: "absolute",
    width: "100%",
    height: "1109px",
    left: "0px",
    top: "-849px",
    background:
      "radial-gradient(56.05% 179.86% at 50.14% 100%, #3759CA 0%, #00B8E0 47.66%, #FFB800 100%)",
    opacity: "0.1",
    filter: "blur(150px)",
  },
  closeTag: {
    position: "absolute",
    width: "50.19px",
    height: "55.68px",
    left: "calc(50% - 1px/2 - 550px)",
    top: "122.56px",
  } as React.CSSProperties,
  closeTagMobile: {
    position: "absolute",
    width: "50.19px",
    height: "55.68px",
    right: "28px",
    top: "94px",
  } as React.CSSProperties,
  line2: {
    position: "absolute",
    width: "1px",
    height: "601px",
    left: "calc(50% - 1px/2 - 552px)",
    top: "-100px",
    background: "#31373E",
  } as React.CSSProperties,
  line6: {
    position: "absolute",
    width: "1px",
    height: "601px",
    left: "calc(50% - 1px/2 - 277px)",
    top: "-100px",
    background: "#31373E",
  } as React.CSSProperties,
  line3: {
    position: "absolute",
    width: "1px",
    height: "601px",
    left: "calc(50% - 1px/2 + 1px)",
    top: "-100px",
    background: "#31373E",
  } as React.CSSProperties,
  line8: {
    position: "absolute",
    width: "1px",
    height: "206px",
    left: "calc(50% - 1px/2 + 118px)",
    bottom: "873px",
    background: "#31373E",
  } as React.CSSProperties,
  line4: {
    position: "absolute",
    width: "1px",
    height: "601px",
    left: "calc(50% - 1px/2 + 554px)",
    top: "-100px",
    background: "#31373E",
  } as React.CSSProperties,
  line5: {
    position: "absolute",
    width: "1px",
    height: "601px",
    left: "calc(50% - 1px/2 + 278px)",
    top: "-100px",
    background: "#31373E",
  } as React.CSSProperties,
  doubleArrow: {
    position: "absolute",
    width: "160px",
    left: "calc(50% - 1px/2 + 118px)",
    top: "150px",
  } as React.CSSProperties,
  titleTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: "192px",
    gap: "32px",
    width: "100%",
    zIndex: 2,
  },
  titleText: {
    maxWidth: "899px",
    left: "0px",
    top: "0px",
    color: "#ffffff",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "64px",
    lineHeight: "64px",
  },
  titleSubtextContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    alignItems: "center",
    padding: "0px",
    width: "100%",
    gap: "24px",
  },
  titleSubtext: {
    maxWidth: "832px",
    color: "#ffffff",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "25px",
    lineHeight: "26px",
  },
  servicesButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 10px 0 20px",
    gap: "10px",
    width: "278px",
    height: "60px",
    borderRadius: "6px",
    backgroundColor: "#7696FF",
    "&:hover": {
      backgroundColor: "#456AE6",
    },
  },
  servicesButtonText: {
    color: "#ffffff",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.0125em",
  },
  titleTextOutlined: { WebkitTextStroke: "1px #ffffff", color: "transparent" },
  titleTextYellow: { color: "#FFB800" },
};
