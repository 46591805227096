import React, { useState, useEffect } from "react";

import { Services } from "../../constants";

import { Box, Drawer, IconButton } from "@mui/material";

import { styles } from "./styles";
import SubSidebarMenu from "./SubSidebarMenu";

interface SubSidebarProps {
  open: boolean;
  handleCloseSubSidebar: () => void;
  handleCloseAllSidebar: () => void;
  service: string;
}

const SubSidebar = ({
  open,
  handleCloseSubSidebar,
  handleCloseAllSidebar,
  service,
}: SubSidebarProps) => {
  const [bgColor, setBgColor] = useState("");

  useEffect(() => {
    switch (service) {
      case Services.Software:
        setBgColor("#5579EE");
        break;
      case Services.Hardware:
        setBgColor("#00E4BB");
        break;
      case Services.Production:
        setBgColor("#FFB800");
        break;
      case Services.Certificate:
        setBgColor("#40EBF4");
        break;
      default:
        break;
    }
  }, [service]);

  return (
    <Drawer anchor="right" open={open} onClose={handleCloseAllSidebar}>
      <Box sx={{ ...styles.sidebar.base, backgroundColor: bgColor }}>
        <Box sx={styles.sidebar.topBox}>
          <img src="img/logo.svg" alt="logo" />
          <IconButton onClick={handleCloseAllSidebar}>
            <img src="img/closeIcon.svg" alt="closeIcon" />
          </IconButton>
        </Box>
        <Box
          sx={
            service === "software"
              ? styles.subSidebar.separatorLight
              : styles.subSidebar.separator
          }
        />
        <SubSidebarMenu
          service={service}
          handleCloseAllSidebar={handleCloseAllSidebar}
          handleCloseSubSidebar={handleCloseSubSidebar}
        />
      </Box>
    </Drawer>
  );
};

export default SubSidebar;
