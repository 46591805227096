export const styles = {
  badgeContainer: {
    position: "fixed",
    bottom: "20px",
    right: "20px",
    backgroundColor: "#B3BDC7",
    borderRadius: "10%",
    height: "180px",
    width: "260px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  badgeLogo: {
    width: "250px",
  } as React.CSSProperties,
  badgeContainerMobile: {
    position: "fixed",
    bottom: "10px",
    right: "10px",
    backgroundColor: "#B3BDC7",
    borderRadius: "10%",
    height: "110px",
    width: "160px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  badgeLogoMobile: {
    width: "150px",
  } as React.CSSProperties,
};
