export const styles = {
  sidebar: {
    base: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      isolation: "isolate",
      width: "375px",
      height: "100%",
      backgroundColor: "#222425",
      padding: "24px 0px",
      position: "relative",
      overflow: "hidden",
    },
    topBox: {
      padding: "0px 24px 24px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    separator: {
      width: "100%",
      height: "1px",
      backgroundColor: "#31373E",
    },
    subMenuList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: "32px 24px",
      gap: "48px",
    },
    subMenuListItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
    },
    subMenuListText: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "25px",
      lineHeight: "26px",
    },
    subSidebarOpenButton: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",
      width: "40px",
      height: "40px",
      borderRadius: "4px",
      backgroundColor: "#31373E",
    },
    rightArrow: {
      transform: "rotate(270deg)",
    } as React.CSSProperties,
    link: {
      textDecoration: "none",
      zIndex: 2,
    } as React.CSSProperties,
    mainMenuList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "24px",
      width: "100%",
      height: "247px",
    },
    mainMenuListText: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "25px",
      lineHeight: "26px",
      color: "#FFFFFF",
      "&:hover": {
        cursor: "pointer",
      },
    },
    bgLight: {
      position: "absolute",
      width: "991px",
      height: "565px",
      left: "-300px",
      bottom: "-300px",
      background:
        "radial-gradient(56.05% 179.86% at 50.14% 100%, #3759CA 0%, #00B8E0 47.66%, #FFB800 100%)",
      opacity: "0.1",
      filter: "blur(150px)",
    },
  },
  subSidebar: {
    base: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      isolation: "isolate",
      width: "375px",
      height: "100%",
      padding: "24px 0px",
      position: "relative",
      overflow: "hidden",
    },
    topBox: {
      padding: "0px 24px 24px",
      width: "100%",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    separator: {
      width: "100%",
      height: "1px",
      backgroundColor: "#31373E",
      opacity: 0.1,
    },
    separatorLight: {
      width: "100%",
      height: "1px",
      backgroundColor: "#FFFFFF",
      opacity: 0.1,
    },
    leftArrow: {
      transform: "rotate(90deg)",
    } as React.CSSProperties,
    navItemText: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "25px",
      lineHeight: "26px",
      textAlign: "right",
    },
    navBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      padding: "32px 24px",
      gap: "48px",
    },
    navItem: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      width: "100%",
      justifyContent: "space-between",
    },
    subMenuList: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      width: "100%",
      padding: "32px 24px",
      gap: "48px",
    },
    subMenuText: {
      fontFamily: "Rubik",
      fontStyle: "normal",
      fontWeight: "300",
      fontSize: "25px",
      lineHeight: "26px",
    },
  },
};
