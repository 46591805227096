import React, { useState } from "react";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Drawer, Box, IconButton, Typography, Link } from "@mui/material";

import SubSidebar from "./SubSidebar";
import SidebarMenuItem from "./SidebarMenuItem";
import { Services } from "../../constants";

import { styles } from "./styles";

interface SidebarProps {
  open: boolean;
  handleCloseSidebar: () => void;
  scrollToWhoWeAre?: () => void;
  scrollToContact?: () => void;
  scrollToServices?: () => void;
}

const Sidebar = ({
  open,
  handleCloseSidebar,
  scrollToWhoWeAre,
  scrollToContact,
  scrollToServices,
}: SidebarProps) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [service, setService] = useState("");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navigateToHome = (state: string) => {
    navigate("/", { state });
  };

  const handleCloseSubSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleCloseAllSidebar = () => {
    setIsSidebarOpen(false);
    handleCloseSidebar();
  };

  const handleOpenSubSidebar = (newService: string) => {
    setIsSidebarOpen(true);
    setService(newService);
  };

  const handleContactClick = () => {
    if (scrollToContact && pathname !== "/grants") {
      scrollToContact();
    } else {
      navigateToHome("contact");
    }
  };

  const handleWhoWeAreClick = () => {
    if (scrollToWhoWeAre && pathname === "/") {
      scrollToWhoWeAre();
    } else {
      navigateToHome("whoWeAre");
    }
  };

  const handleServicesClick = () => {
    if (scrollToServices && pathname !== "/") {
      scrollToServices();
    } else {
      navigateToHome("services");
    }
  };

  return (
    <Drawer
      disableRestoreFocus
      anchor="right"
      open={open}
      onClose={handleCloseSidebar}
    >
      <Box sx={styles.sidebar.base}>
        <Box sx={styles.sidebar.topBox}>
          <img src="img/logo.svg" alt="logo" />
          <IconButton onClick={handleCloseSidebar}>
            <img src="img/closeIcon.svg" alt="closeIcon" />
          </IconButton>
        </Box>
        <Box sx={styles.sidebar.separator} />
        <Box sx={styles.sidebar.subMenuList}>
          <SidebarMenuItem
            service={Services.Software}
            color="#7696FF"
            handleOpenSubSidebar={handleOpenSubSidebar}
          />
          <SidebarMenuItem
            service={Services.Hardware}
            color="#00E4BB"
            handleOpenSubSidebar={handleOpenSubSidebar}
          />
          <SidebarMenuItem
            service={Services.Production}
            color="#FFB800"
            handleOpenSubSidebar={handleOpenSubSidebar}
          />
          <SidebarMenuItem
            service={Services.Certificate}
            color="#40EBF4"
            handleOpenSubSidebar={handleOpenSubSidebar}
          />
        </Box>
        <Box sx={styles.sidebar.separator} />
        <Box sx={styles.sidebar.mainMenuList}>
          <Link onClick={handleServicesClick} sx={styles.sidebar.link}>
            <Typography sx={styles.sidebar.mainMenuListText}>
              {t("common.navigation.services")}
            </Typography>
          </Link>
          <Link onClick={handleWhoWeAreClick} sx={styles.sidebar.link}>
            <Typography sx={styles.sidebar.mainMenuListText}>
              {t("common.navigation.aboutUs")}
            </Typography>
          </Link>
          <Link onClick={handleContactClick} sx={styles.sidebar.link}>
            <Typography sx={styles.sidebar.mainMenuListText}>
              {t("common.navigation.contact")}
            </Typography>
          </Link>
          <RouterLink to="/grants" style={styles.sidebar.link}>
            <Typography sx={styles.sidebar.mainMenuListText}>
              {t("common.navigation.grants")}
            </Typography>
          </RouterLink>
        </Box>
        <Box sx={styles.sidebar.bgLight} />
      </Box>
      <SubSidebar
        service={service}
        open={isSidebarOpen}
        handleCloseSubSidebar={handleCloseSubSidebar}
        handleCloseAllSidebar={handleCloseAllSidebar}
      />
    </Drawer>
  );
};

export default Sidebar;
