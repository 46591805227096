import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography } from "@mui/material";

import { styles } from "./styles";

interface ToastMsgProps {
  success?: boolean;
}

const ToastMsg = ({ success = false }: ToastMsgProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.toastBody}>
      <Typography sx={styles.toastText}>
        {success ? t("contact.messageSent") : t("contact.messageError")}
      </Typography>
    </Box>
  );
};

export default ToastMsg;
