export const styles = {
  partnersContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "96px 0px",
    isolation: "isolate",
    gap: "64px",
    width: "100%",
    height: "454px",
    backgroundColor: "#FFB800",
    position: "relative",
    overflow: "hidden",
  },
  partnersTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0",
    gap: "8px",
  },
  partnersH2: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  partnersH5: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  partnersBackground: {
    position: "absolute",
    width: "100%",
    height: "592px",
    left: "0",
    top: "calc(50% - 592px/2 + 27px)",
    opacity: "0.15",
  },
  bgTextProduct: {
    position: "absolute",
    left: "0px",
    top: "42px",
  } as React.CSSProperties,
  bgTextEngineering: {
    position: "absolute",
    right: "0px",
    top: "268.51px",
  } as React.CSSProperties,
  sliderContainer: {
    display: "flex",
    overflow: "hidden",
    userSelect: "none",
    gap: "1rem",
  },
  sliderAnimated: {
    flexShrink: "0",
    display: "flex",
    justifyContent: "space-around",
    minWidth: "100%",
    gap: "1rem",
  },
  sliderLogoWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "110px"
  },
  sliderLogo: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    maxHeight: "110px",
    maxWidth: "300px",
  }
};
