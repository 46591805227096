import React from "react";

export const styles = {
  otherServices: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "96px calc((100% - 1440px)/2)",
    isolation: "isolate",
    gap: "64px",
    width: "100%",
    backgroundColor: "#222425",
    position: "relative",
  },
  otherServicesContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px calc((100% - 1110px)/2)",
    gap: "48px",
    width: "100%",
  },
  otherServicesContentMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "48px",
    width: "100%",
  },
  background: {
    position: "absolute",
    left: "0px",
    top: "0px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
    zIndex: 1,
    padding: "0px calc((100% - 1110px)/2)",
    overflow: "hidden",
    opacity: "0.1",
  },
  bgLine: {
    width: "1px",
    height: "100%",
    backgroundColor: "#FFFFFF",
  },
  otherServicesTitle: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#FFFFFF",
  },
  otherServicesTitleMobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "40px",
    textAlign: "center",
    color: "#FFFFFF",
  },
  otherServicesList: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px calc((100% - 1110px)/2)",
    gap: "8px",
    width: "100%",
    zIndex: 2,
  },
  otherServicesListMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px 24px",
    gap: "8px",
    width: "100%",
    zIndex: 2,
  },
  otherServicesListItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "20px",
    gap: "8px",
    border: "1px solid #FFFFFF",
    width: "100%",
  },
  link: {
    textDecoration: "none",
  } as React.CSSProperties,
  otherServicesListItemText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
  },
  backgroundLight: {
    position: "absolute",
    width: "100%",
    height: "1109px",
    left: "-252px",
    top: "179px",
    background:
      "radial-gradient(56.05% 179.86% at 50.14% 100%, #3759CA 0%, #00B8E0 47.66%, #FFB800 100%)",
    filter: "blur(150px)",
  },
};
