import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { Popover, Box, Typography } from "@mui/material";

import { styles } from "./styles";

interface PopoverProps {
  open: boolean;
  anchorEl: HTMLAnchorElement | null;
  handleClose: () => void;
  anchorOriginVertical: number | "center" | "bottom" | "top";
  transformOriginVertical: number | "center" | "bottom" | "top";
}

const NavigationPopover = ({
  open,
  anchorEl,
  handleClose,
  anchorOriginVertical,
  transformOriginVertical,
}: PopoverProps) => {
  const { t } = useTranslation();

  return (
    <Popover
      disableScrollLock
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: anchorOriginVertical,
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: transformOriginVertical,
        horizontal: "center",
      }}
    >
      <Box sx={styles.popoverContent}>
        <Box sx={styles.submenuItem}>
          <img
            style={{ width: "32px", height: "32px" }}
            src={"/img/servicesGrid/softwareIcon.svg"}
            alt="softwareIcon"
          />
          <Link style={styles.link} to="/software?id=1" onClick={handleClose}>
            <Typography sx={styles.submenuText}>
              {t("common.navigation.software")}
            </Typography>
          </Link>
        </Box>
        <Box sx={styles.separator} />
        <Box sx={styles.submenuItem}>
          <img
            style={{ width: "32px", height: "32px" }}
            src={"/img/servicesGrid/hardwareIcon.svg"}
            alt="hardwareIcon"
          />
          <Link style={styles.link} to="/hardware?id=1" onClick={handleClose}>
            <Typography sx={styles.submenuText}>
              {t("common.navigation.hardware")}
            </Typography>
          </Link>
        </Box>
        <Box sx={styles.separator} />
        <Box sx={styles.submenuItem}>
          <img
            style={{ width: "32px", height: "32px" }}
            src={"/img/servicesGrid/productionIcon.svg"}
            alt="productionIcon"
          />
          <Link style={styles.link} to="/production?id=1" onClick={handleClose}>
            <Typography sx={styles.submenuText}>
              {t("common.navigation.production")}
            </Typography>
          </Link>
        </Box>
        <Box sx={styles.separator} />
        <Box sx={styles.submenuItem}>
          <img
            style={{ width: "32px", height: "32px" }}
            src={"/img/servicesGrid/certificateIcon.svg"}
            alt="certificateIcon"
          />
          <Link
            style={styles.link}
            to="/certificate?id=1"
            onClick={handleClose}
          >
            <Typography sx={styles.submenuText}>
              {t("common.navigation.certificate")}
            </Typography>
          </Link>
        </Box>
      </Box>
    </Popover>
  );
};

export default NavigationPopover;
