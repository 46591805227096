import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

const WhoWeAre = React.forwardRef((_props, ref) => {
  const { t } = useTranslation();
  const mobileSize = useMediaQuery("(max-width: 1130px)");

  return (
    <Box ref={ref} sx={styles.whoWeAre}>
      <Box sx={styles.background}>
        <Box
          sx={{
            ...styles.line2,
            left: mobileSize ? "24px" : styles.line2.left,
          }}
        />
        <Box sx={styles.line3} />
        <Box sx={styles.line8} />
        <Box sx={styles.line5} />
        {!mobileSize ? (
          <>
            <Box sx={styles.line4} />
            <Box sx={styles.line6} />
          </>
        ) : null}

        <Box sx={styles.light} />
        <img style={styles.doubleArrow} src="/img/doubleArrow2.svg" alt="" />
        <img
          style={mobileSize ? styles.closeTagMobile : styles.closeTag}
          src="/img/closeTag.svg"
          alt=""
        />
      </Box>
      <Box
        sx={{
          ...styles.content,
          padding: mobileSize ? "0px 24px" : styles.content.padding,
        }}
      >
        <Typography
          sx={mobileSize ? styles.whoWeAreTitleMobile : styles.whoWeAreTitle}
          variant="h2"
        >
          {t("whoWeAre.title")}
        </Typography>
        {!mobileSize ? (
          <Box sx={styles.skills}>
            <Box sx={styles.skillBox}>
              <Typography
                sx={{ ...styles.skillBoxText, color: "#00E4BB" }}
                variant="h5"
              >
                {t("whoWeAre.mech")}
              </Typography>
            </Box>
            <img src="/img/add.svg" alt="add" />
            <Box sx={styles.skillBox}>
              <Typography
                sx={{ ...styles.skillBoxText, color: "#FFB800" }}
                variant="h5"
              >
                {t("whoWeAre.prod")}
              </Typography>
            </Box>
            <img src="/img/add.svg" alt="add" />
            <Box sx={styles.skillBox}>
              <Typography
                sx={{ ...styles.skillBoxText, color: "#40EBF4" }}
                variant="h5"
              >
                {t("whoWeAre.pm")}
              </Typography>
            </Box>
            <img src="/img/add.svg" alt="add" />
            <Box sx={styles.skillBox}>
              <Typography
                sx={{ ...styles.skillBoxText, color: "#7696FF" }}
                variant="h5"
              >
                {t("whoWeAre.dev")}
              </Typography>
            </Box>
          </Box>
        ) : null}
        <Box sx={mobileSize ? styles.ourStoryMobile : styles.ourStory}>
          <Box sx={mobileSize ? styles.topLineMobile : styles.topLine} />
          <Box sx={styles.ourStoryTextBox}>
            <Typography sx={styles.ourStoryText}>
              {t("whoWeAre.text1")}
            </Typography>
            <Typography
              sx={{
                ...styles.ourStoryText,
                color: "#FFB800",
                letterSpacing: "0.001em",
                lineHeight: "20px",
              }}
            >
              {t("whoWeAre.text2")}
            </Typography>
            <Typography sx={styles.ourStoryText}>
              {t("whoWeAre.text3")}
            </Typography>
          </Box>
          <img
            style={{ maxWidth: "554px", width: "100%" }}
            src="/img/founders/founders.png"
            alt="founders"
          />
        </Box>
      </Box>
    </Box>
  );
});

export default WhoWeAre;
