export const styles = {
  footer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px calc((100% - 1440px)/2)",
    isolation: "isolate",
    gap: "64px",
    width: "100%",
    backgroundColor: "#222425",
  },
  map: {
    backgroundImage: "url(/img/footer/map.png)",
    width: "100%",
    height: "494px",
    position: "relative",
  },
  mapMarker: {
    position: "absolute",
    width: "56px",
    height: "56px",
    left: "1060px",
    top: "208px",
  } as React.CSSProperties,
  doubleArrow: {
    position: "absolute",
    width: "360px",
    left: "721px",
    top: "254px",
    zIndex: 2,
  } as React.CSSProperties,
  mapMarkerMobile: {
    width: "56px",
    height: "56px",
  } as React.CSSProperties,
  doubleArrowMobile: {
    zIndex: 2,
  } as React.CSSProperties,
  footerContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    isolation: "isolate",
    position: "absolute",
    top: "0px",
    left: "0px",
    gap: "64px",
    width: "100%",
    height: "494px",
    zIndex: "3",
  },
  topButtonBox: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "center",
    marginTop: "32px",
    width: "100%",
    height: "48px",
  },
  topButton: {
    backgroundColor: "#7696FF",
    borderRadius: "5px",
    width: "48px",
    height: "48px",
    minWidth: "32px",
    "&:hover": {
      backgroundColor: "#456AE6",
    },
  },
  upArrow: {
    transform: "rotate(180deg)",
  } as React.CSSProperties,
  footerTextContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    position: "relative",
    left: "165px",
    gap: "48px",
    width: "555px",
  },
  footerTextContainerMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "relative",
    gap: "48px",
    width: "100%",
  },
  footerTextH2: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "64px",
    lineHeight: "56px",
    color: "#FFFFFF",
  },
  footerTextH2mobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "40px",
    color: "#FFFFFF",
  },
  addressBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "24px",
    gap: "16px",
    width: "100%",
    backgroundColor: "#222425",
  },
  addressH5: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },
  addressH4: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "25px",
    lineHeight: "26px",
    color: "#FFFFFF",
  },
  addressH5mobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18x",
    lineHeight: "24px",
    color: "#FFFFFF",
    letterSpacing: "0.0015em",
  },
  addressH4mobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.001em",
    color: "#FFFFFF",
  },
  footerMenu: {
    display: "flex",
    flexWrap: "wrap",
    gap: "30px",
    width: "100%",
    padding: "0px calc((100% - 1110px)/2)",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  navigation: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "53px",
    zIndex: 2,
  },
  copyright: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.005em",
    color: "#FFFFFF",
  },
  navLink: {
    textDecoration: "none",
    "&:hover": {
      color: "#FFFFFF",
      cursor: "pointer",
    },
  },
  navLinkText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.001em",
    color: "#FFFFFF",
  },
  background: {
    position: "absolute",
    left: "0px",
    top: "0px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    zIndex: 0,
    padding: "0px calc((100% - 1110px)/2)",
    overflow: "hidden",
    opacity: "0.1",
  },
  bgLine: {
    width: "1px",
    height: "494px",
    backgroundColor: "#FFFFFF",
  },
  bgLineMobile: {
    width: "1px",
    height: "754px",
    backgroundColor: "#FFFFFF",
  },
  backgroundLight: {
    position: "absolute",
    width: "100%",
    height: "1109px",
    bottom: "180px",
    background:
      "radial-gradient(56.05% 179.86% at 50.14% 100%, #3759CA 0%, #00B8E0 47.66%, #FFB800 100%)",
    filter: "blur(150px)",
  },
};
