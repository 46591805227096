import React from "react";

export const styles = {
  popoverContent: {
    backgroundColor: "#3759CA",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    padding: "24px",
    gap: "16px",
  },
  submenuItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    gap: "8px",
  },
  separator: {
    width: "278px",
    height: "1px",
    backgroundColor: "#FFFFFF",
    opacity: "0.1",
  },
  submenuText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.005em",
    color: "#FFFFFF",
  },
  link: {
    textDecoration: "none",
  } as React.CSSProperties,
};
