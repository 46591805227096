import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

const NotFound = () => {
  const { t } = useTranslation();
  const mobileSize = useMediaQuery("(max-width: 1130px)");

  return (
    <Box sx={styles.notFoundPage}>
      <img
        style={mobileSize ? styles.mobileLogo : styles.logo}
        src="img/logo.svg"
        alt="logo"
      />
      <Typography
        sx={mobileSize ? styles.notFoundTitleMobile : styles.notFoundTitle}
        variant="h1"
      >
        404
      </Typography>
      <Typography
        sx={mobileSize ? styles.notFoundTextMobile : styles.notFoundText}
        variant="h3"
      >
        {t("notFound.title")}
      </Typography>
      <Link style={styles.link} to="/">
        <Typography
          sx={mobileSize ? styles.notFoundTextMobile : styles.notFoundText}
          variant="h3"
        >
          {t("notFound.backToHome")}
        </Typography>
      </Link>
    </Box>
  );
};

export default NotFound;
