import React from "react";

export const styles = {
  testimonials: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "96px 0px",
    isolation: "isolate",
    width: "100%",
    height: "628px",
    position: "relative",
    backgroundColor: "#7696FF",
    overflow: "hidden",
  },
  testimonialsBackground: {
    position: "absolute",
    width: "1440px",
    height: "592px",
    left: "calc(50% - 1440px/2)",
    top: "calc(50% - 592px/2 + 27px)",
    filter: "blur(150px)",
  },
  bgEllipse1: {
    position: "absolute",
    width: "830px",
    height: "830px",
    left: "305px",
    top: "-238px",
    backgroundColor: "#3759CA",
    borderRadius: "100%",
  },
  bgEllipse2: {
    position: "absolute",
    width: "655px",
    height: "655px",
    left: "814px",
    top: "332px",
    backgroundColor: "#FFB800",
    borderRadius: "100%",
  },
  bgEllipse3: {
    position: "absolute",
    width: "892px",
    height: "892px",
    left: "-276px",
    top: "146px",
    backgroundColor: "#00E4BB",
    borderRadius: "100%",
  },
  quoteMarks: {
    position: "relative",
    width: "638.13px",
    height: "244.13px",
    opacity: "0.4",
  },
  topQuoteMark: {
    position: "absolute",
    width: "91.41px",
    height: "77.86px",
    left: "-40px",
    top: "10px",
  } as React.CSSProperties,
  bottomQuoteMark: {
    position: "absolute",
    width: "91.13px",
    height: "78.13px",
    left: "619px",
    top: "150px",
  } as React.CSSProperties,
  testimonialsTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px",
    gap: "8px",
    zIndex: "3",
  },
  testimonialsH2: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "48px",
    lineHeight: "56px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  testimonialsH5: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  testimonialsH2mobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "34px",
    lineHeight: "40px",
    color: "#FFFFFF",
    textAlign: "center",
  },
  review: {
    display: "flex",
    position: "relative",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px calc((100% - 540px) / 2)",
    gap: "64px",
    width: "100%",
    height: "284px",
    zIndex: "2",
  },
  reviewText: {
    fontFamily: "Rubik",
    fontStyle: "italic",
    fontWeight: "300",
    fontSize: "25px",
    lineHeight: "32px",
    color: "#FFFFFF",
    textAlign: "left",
  },
  reviewer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px",
    gap: "16px",
    marginTop: "64px",
  },
  nameTitle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
  },
  name: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#FFFFFF",
  },
  title: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.005em",
    color: "#FFFFFF",
  },
  nameMobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "25px",
    lineHeight: "26px",
    color: "#FFFFFF",
  },
  titleMobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.005em",
    color: "#FFFFFF",
  },
  reviewerLogo: {
    maxWidth: "210px",
    maxHeight: "47px",
  } as React.CSSProperties,
  reviewerLogoMobile: {
    maxWidth: "143px",
    maxHeight: "32px",
  } as React.CSSProperties,
};
