import React from "react";
import { useTranslation } from "react-i18next";

import partners from "./partnersList.json";

import { Box, keyframes, Typography } from "@mui/material";

import { styles } from "./styles";

const Partners = () => {
  const slide = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(calc(-100% - 1rem));
  }
  `;

  const { t } = useTranslation();

  return (
    <Box sx={styles.partnersContainer}>
      <Box sx={styles.partnersBackground}>
        <img
          style={styles.bgTextProduct}
          src="/img/product.svg"
          alt="product"
        />
        <img
          style={styles.bgTextEngineering}
          src="/img/engineering.svg"
          alt="engineering"
        />
      </Box>
      <Box sx={styles.partnersTitle}>
        <Typography sx={styles.partnersH2} variant="h2">
          {t("partners.title")}
        </Typography>
        <Typography sx={styles.partnersH5} variant="h5">
          {t("partners.subTitle")}
        </Typography>
      </Box>
      <Box sx={{
            ...styles.sliderContainer,
          }}
      >
        {/* based on this guide: https://ryanmulligan.dev/blog/css-marquee/ */}
        <Box sx={{
          ...styles.sliderAnimated,
          animation: `${slide} 50s linear infinite`,
        }}>
          { // putting images 2 times here so animation seems infinite
          partners.map((i) => {
            return (
              <Box key={i.url} sx={styles.sliderLogoWrapper}>
                <a target="_blank" rel="noreferrer" href={i.url}>
                  <img src={i.img} alt="" style={styles.sliderLogo} />
                </a>
              </Box>
            );
          })}
        </Box>
        <Box aria-hidden="true" sx={{
          ...styles.sliderAnimated,
          animation: `${slide} 50s linear infinite`,
        }}>
          {partners.map((i) => {
            return (
              <Box key={i.url} sx={styles.sliderLogoWrapper}>
                <a target="_blank" rel="noreferrer" href={i.url}>
                  <img src={i.img} alt="" style={styles.sliderLogo} />
                </a>
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Partners;
