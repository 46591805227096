import React, { useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import emailjs from "emailjs-com";
import { toast } from "react-toastify";

import ToastMsg from "../Toast/ToastMsg";

import {
  Box,
  Typography,
  TextField,
  Button,
  useMediaQuery,
} from "@mui/material";

import { styles } from "./styles";

interface ContactProps {
  bgColor: string;
  textColor: string;
  buttonColor: string;
}

const Contact = React.forwardRef(
  ({ bgColor, textColor, buttonColor }: ContactProps, ref) => {
    const { t } = useTranslation();
    const [disableSend, setDisableSend] = useState(false);
    const mobileSize = useMediaQuery("(min-width: 1300px)");

    const phoneRegex = /^\d*$/;

    const validationSchema = yup.object({
      name: yup.string().required("Kötelező mező"),
      email: yup
        .string()
        .email("Adjon meg érvényes email címet")
        .required("Kötelező mező"),
      phone: yup
        .string()
        .matches(phoneRegex, "A telefonszám csak számokból állhat")
        .max(20, "Maximum 20 karakter"),
      message: yup.string().required("Kötelező mező"),
    });

    const formik = useFormik({
      initialValues: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        emailjs
          .send(
            "service_amst17r",
            "template_28a15um",
            values,
            "user_ZKqwvAmG8bq4OXLKRWzbd"
          )
          .then(
            () => {
              toast.success(<ToastMsg success />, {
                style: {
                  height: "100px",
                },
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            },
            (_error) => {
              toast.error(<ToastMsg />, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
            }
          );
        setDisableSend(true);
        formik.resetForm();
        setTimeout(() => {
          setDisableSend(false);
        }, 10000);
      },
    });

    return (
      <Box
        ref={ref}
        sx={{
          ...styles.contact,
          backgroundColor: bgColor,
          padding: !mobileSize ? "64px 24px" : styles.contact.padding,
        }}
      >
        <Box sx={styles.partnersBackground}>
          <img
            style={styles.bgTextProduct}
            src="/img/product.svg"
            alt="product"
          />
          <img
            style={styles.bgTextEngineering}
            src="/img/engineering.svg"
            alt="engineering"
          />
        </Box>
        <Box sx={styles.contactTitle}>
          <Typography
            sx={{ ...styles.contactH2, color: textColor }}
            variant="h2"
          >
            {t("contact.title")}
          </Typography>
          <Typography
            sx={{ ...styles.contactH4, color: textColor }}
            variant="h4"
          >
            {t("contact.subTitle")}
          </Typography>
        </Box>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          <Box sx={mobileSize ? styles.form : styles.formMobile}>
            <TextField
              sx={{
                ...styles.formField,
                color: textColor,
                "& .MuiInputBase-root": {
                  border: `2px solid ${textColor}`,
                },
              }}
              fullWidth={!mobileSize}
              id="name"
              name="name"
              label={t("contact.name")}
              value={formik.values.name}
              onChange={formik.handleChange}
              error={formik.touched.name && Boolean(formik.errors.name)}
              helperText={formik.touched.name && formik.errors.name}
            />
            <TextField
              sx={{
                ...styles.formField,
                color: textColor,
                "& .MuiInputBase-root": {
                  border: `2px solid ${textColor}`,
                },
              }}
              fullWidth={!mobileSize}
              id="email"
              name="email"
              label={t("contact.email")}
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
            <TextField
              sx={{
                ...styles.formField,
                color: textColor,
                "& .MuiInputBase-root": {
                  border: `2px solid ${textColor}`,
                },
              }}
              fullWidth={!mobileSize}
              id="phone"
              name="phone"
              label={t("contact.phone")}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={
                formik.errors.phone ? (
                  formik.errors.phone
                ) : (
                  <Typography
                    component={"span"}
                    sx={{ ...styles.helperText, color: textColor }}
                  >
                    {t("contact.optional")}
                  </Typography>
                )
              }
            />
            {!mobileSize ? (
              <TextField
                sx={{
                  ...styles.formField,
                  color: textColor,
                  "& .MuiInputBase-root": {
                    border: `2px solid ${textColor}`,
                  },
                }}
                fullWidth={!mobileSize}
                id="message"
                name="message"
                label={t("contact.message")}
                value={formik.values.message}
                onChange={formik.handleChange}
                error={formik.touched.message && Boolean(formik.errors.message)}
                helperText={formik.touched.message && formik.errors.message}
              />
            ) : null}

            {disableSend ? (
              <Button
                sx={styles.disabledButton}
                disabled
                fullWidth={!mobileSize}
              >
                <Typography sx={styles.submitButtonText}>
                  {t("common.send")}
                </Typography>
                <img src="/img/bell.svg" alt="bell" />
              </Button>
            ) : (
              <Button
                sx={{ ...styles.submitButton, backgroundColor: buttonColor }}
                type="submit"
                fullWidth={!mobileSize}
              >
                <Typography sx={styles.submitButtonText}>
                  {t("common.send")}
                </Typography>
                <img src="/img/bell.svg" alt="bell" />
              </Button>
            )}
          </Box>
          {mobileSize ? (
            <TextField
              sx={{
                ...styles.formField,
                color: textColor,
                width: "100%",
                "& .MuiInputBase-root": {
                  border: `2px solid ${textColor}`,
                },
              }}
              id="message"
              name="message"
              label={t("contact.message")}
              value={formik.values.message}
              onChange={formik.handleChange}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
            />
          ) : null}
        </form>
      </Box>
    );
  }
);

export default Contact;
