export const styles = {
  servicesGridItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "32px",
    gap: "20px",
    width: "276.75px",
    minHeight: "690px",
    border: "1px solid #31373E",
    "&:hover": {
      background: "#31373E",
    },
  },
  iconButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "0",
    width: "48px",
    height: "48px",
    backgroundColor: "#31373E",
    borderRadius: "5px",
  },
  gridItemH3: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "32px",
    lineHeight: "36px",
  },
  gridItemH3mobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "25px",
    lineHeight: "26px",
  },
  gridItemDescription: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.005em",
    color: "#8A959C",
  },
  gridItemNavBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "20px",
    flexGrow: 1,
  },
  gridItemNavLinks: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    color: "#FFFFFF",
  },
  gridItemNavLinksMobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    color: "#FFFFFF",
    letterSpacing: "0.001em",
  },
  link: {
    textDecoration: "none",
  } as React.CSSProperties,
};
