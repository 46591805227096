import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, Link as RouterLink, useLocation } from "react-router-dom";

import {
  Box,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";

import NavigationPopover from "../NavigationPopover/NavigationPopover";
import LanguageSelect from "../LanguageSelect/LanguageSelect";

import { styles } from "./styles";

interface HeaderProps {
  scrollToWhoWeAre?: () => void;
  scrollToContact?: () => void;
  handleOpenSidebar?: () => void;
  service?: boolean;
}

const Header = ({
  scrollToWhoWeAre,
  scrollToContact,
  handleOpenSidebar,
  service,
}: HeaderProps) => {
  const [bgOpacity, setBgOpacity] = useState(0);
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const mobileSize = useMediaQuery("(max-width: 1130px)");

  const listenToScrollEvent = () => {
    if (window.scrollY) {
      setBgOpacity(window.scrollY / 150);
    } else {
      setBgOpacity(0);
    }
  };

  const navigateToHome = (state: string) => {
    navigate("/", { state });
  };

  useEffect(() => {
    window.addEventListener("scroll", listenToScrollEvent);
  }, []);

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const handleWhoWeAreClick = () => {
    if (scrollToWhoWeAre && pathname === "/") {
      scrollToWhoWeAre();
    } else {
      navigateToHome("whoWeAre");
    }
  };

  const handleContactClick = () => {
    if (scrollToContact && pathname !== "/grants") {
      scrollToContact();
    } else {
      navigateToHome("contact");
    }
  };

  return (
    <Box
      sx={{
        ...styles.header,
        justifyContent: mobileSize
          ? "space-around"
          : styles.header.justifyContent,
      }}
    >
      <Box
        sx={{
          ...styles.headerBackground,
          opacity: bgOpacity,
        }}
      />
      <Box
        onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}
        sx={styles.logo}
      >
        <RouterLink to="/">
          <img src="img/logo.svg" alt="logo" />
        </RouterLink>
      </Box>
      {mobileSize ? (
        <IconButton onClick={handleOpenSidebar}>
          <img src="/img/hamburgerMenu.svg" alt="" />
        </IconButton>
      ) : (
        <>
          <Box sx={styles.navbar}>
            <Link sx={styles.link} onClick={handleClick}>
              <Typography sx={styles.navLink} variant="subtitle2">
                {t("common.navigation.services")}
              </Typography>
            </Link>
            <Link sx={styles.link} onClick={handleWhoWeAreClick}>
              <Typography sx={styles.navLink} variant="subtitle2">
                {t("common.navigation.aboutUs")}
              </Typography>
            </Link>
            <Link sx={styles.link} onClick={handleContactClick}>
              <Typography sx={styles.navLink} variant="subtitle2">
                {t("common.navigation.contact")}
              </Typography>
            </Link>
            <RouterLink style={styles.link} to="/grants">
              <Typography sx={styles.navLink} variant="subtitle2">
                {t("common.navigation.grants")}
              </Typography>
            </RouterLink>
          </Box>
          {/* <LanguageSelect /> */}
        </>
      )}
      <NavigationPopover
        open={open}
        anchorEl={anchorEl}
        handleClose={handleClose}
        anchorOriginVertical="bottom"
        transformOriginVertical="top"
      />
    </Box>
  );
};

export default Header;
