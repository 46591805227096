export const styles = {
  notFoundPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px",
    width: "100%",
    height: "100vh",
    gap: "30px",
  },
  logo: {
    width: "500px",
  },
  mobileLogo: {
    width: "250px",
  },
  notFoundTitle: {
    color: "#ffffff",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "100px",
    lineHeight: "50px",
    paddingBottom: "20px",
    marginTop: "20px",
  },
  notFoundTitleMobile: {
    color: "#ffffff",
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "50px",
    lineHeight: "25px",
    paddingBottom: "10px",
    marginTop: "10px",
  },
  notFoundText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "30px",
    lineHeight: "24px",
    color: "#FFFFFF",
    letterSpacing: "0.005em",
  },
  notFoundTextMobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "16px",
    color: "#FFFFFF",
    letterSpacing: "0.005em",
  },
  link: {
    textDecoration: "underline white",
  },
};
