import React from "react";

import { Box, Grid } from "@mui/material";

import { styles } from "./styles";
import ServicesGridItem from "../ServicesGridItem/ServicesGridItem";
import { Services } from "../../constants";

const ServicesGrid = React.forwardRef((_props, ref) => {
  return (
    <Box sx={styles.scrollContainer} ref={ref}>
      <Grid sx={styles.services} container>
        <ServicesGridItem service={Services.Software} color="#7696FF" />
        <ServicesGridItem service={Services.Hardware} color="#00E4BB" />
        <ServicesGridItem service={Services.Production} color="#FFB800" />
        <ServicesGridItem service={Services.Certificate} color="#40EBF4" />
      </Grid>
    </Box>
  );
});

export default ServicesGrid;
