import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import LandingPage from "./components/LandingPage/LandingPage";
import NotFound from "./components/NotFound/NotFound";
import ServicePage from "./components/ServicesPage/ServicePage";
import GrantsPage from "./components/GrantsPage/GrantsPage";
import { Services } from "./constants";
import i18n from "./i18n";

import "./index.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <NotFound />,
  },
  {
    path: "/:service",
    element: <ServicePage />,
    errorElement: <NotFound />,
    loader: ({ params }) => {
      const service = params.service || "";
      if (!Object.values<string>(Services).includes(service)) {
        throw new Response("Not Found", { status: 404 });
      }
      return true;
    },
  },
  {
    path: "/grants",
    element: <GrantsPage />,
    errorElement: <NotFound />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <RouterProvider router={router} />
    </I18nextProvider>
  </React.StrictMode>
);
