export const productionStyles = {
  servicesPage: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    isolation: "isolate",
    position: "relative",
    width: "100%",
    backgroundColor: "#222425",
  },
  heroServices: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0px calc((100% - 1110px)/2) 96px",
    gap: "48px",
    isolation: "isolate",
    backgroundColor: "#222425",
    width: "100%",
  },
  title: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0px",
    gap: "65px",
    width: "100%",
    zIndex: 2,
    marginTop: "128px",
  },
  titleMobile: {
    display: "flex",
    flexDirection: "column-reverse",
    alignItems: "flex-start",
    padding: "0px",
    gap: "65px",
    width: "100%",
    zIndex: 2,
  },
  titleText: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "0px",
    gap: "32px",
  },
  titleTextH2: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "64px",
    lineHeight: "64px",
    color: "#FFB800",
  },
  titleTextH2mobile: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "48px",
    lineHeight: "48px",
    color: "#FFB800",
  },
  titleLogo: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "298.15px",
    position: "relative",
  },
  titleDescription: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px",
    gap: "64px",
    maxWidth: "824px",
  },
  titleDescriptionText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.005em",
    color: "#FFFFFF",
    maxWidth: "380px",
  },
  background: {
    position: "absolute",
    left: "0px",
    top: "-208px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
    height: "138%",
    justifyContent: "space-between",
    zIndex: 1,
    padding: "0px calc((100% - 1110px)/2)",
    overflow: "hidden",
  },
  bgLine: {
    width: "1px",
    height: "100%",
    backgroundColor: "#31373E",
  },
  bgLine2: {
    width: "1px",
    height: "648px",
    backgroundColor: "#222425",
  },
  bigArrow: {
    position: "absolute",
    left: "0px",
    bottom: "0px",
  } as React.CSSProperties,
  mediumArrow: {
    position: "absolute",
    left: "0px",
    top: "0px",
  } as React.CSSProperties,
  smallArrow: {
    position: "absolute",
    right: "0px",
    top: "90px",
  } as React.CSSProperties,
  backgroundLight: {
    position: "absolute",
    width: "100%",
    height: "1109px",
    left: "-244px",
    top: "280px",
    background:
      "radial-gradient(56.05% 179.86% at 50.14% 100%, #3759CA 0%, #00B8E0 47.66%, #FFB800 100%)",
    opacity: "0.1",
    filter: "blur(150px)",
  },
  serviceTypes: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px 0px",
    gap: "64px",
    isolation: "isolate",
    backgroundColor: "#FFB800",
    width: "100%",
    height: "648px",
  },
  serviceTypesMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    backgroundColor: "#FFB800",
  },
  serviceTypesContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    padding: "0px calc((100% - 1110px)/2) 16px",
    gap: "64px",
    isolation: "isolate",
    width: "100%",
    zIndex: 2,
  },
  serviceTypesContentMobile: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "48px 32px 32px",
    gap: "20px",
    scrollMargin: "80px",
  },
  serviceTypesContentMobileDark: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "48px 32px 32px",
    gap: "20px",
    backgroundColor: "#FFD76F",
    scrollMargin: "80px",
  },
  serviceTypesList: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "32px 0px",
    gap: "4px",
    maxWidth: "444px",
  },
  serviceTypesListItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "20px 24px",
    gap: "8px",
    width: "444px",
    height: "72px",
    border: "1px solid #222425",
    color: "#222425",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#FFE49D",
    },
  },
  serviceTypesListItemTitleBox: {
    border: "1px solid #222425",
    padding: "24px 16px",
    width: "100%",
    color: "#222425",
  },
  serviceTypesListItemSelected: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 24px",
    gap: "8px",
    width: "444px",
    height: "72px",
    color: "#222425",
    backgroundColor: "#FFE49D",
    "&:hover": {
      cursor: "pointer",
    },
  },
  serviceTypesListItemText: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "20px",
    lineHeight: "24px",
    color: "inherit",
  },
  serviceTypesDescription: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    padding: "48px 32px 32px",
    gap: "20px",
  },
  serviceTypesDescriptionTitle: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "300",
    fontSize: "32px",
    lineHeight: "36px",
    color: "#222425",
  },
  serviceTypesDescriptionBold: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "24px",
    letterSpacing: "0.0015em",
    color: "#222425",
  },
  serviceTypesDescriptionNormal: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.005em",
    color: "#222425",
  },
  serviceLogo: {
    position: "absolute",
    right: "0",
    top: "45px",
  } as React.CSSProperties,
  interestedButton: {
    backgroundColor: "#7696FF",
    width: "180px",
    height: "56px",
    "&:hover": {
      backgroundColor: "#456AE6",
    },
  },
  interestedButtonText: {
    color: "#FFFFFF",
    fontFamily: "Montserrat",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "22px",
    letterSpacing: "0.0125em",
  },
  contact: {
    backgroundColor: "#40EBF4",
    textColor: "#222425",
    buttonColor: "#FFB800",
  },
};
