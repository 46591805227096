export const styles = {
  header: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "20px calc((100% - 1107px)/2)",
    gap: "40px",
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 3,
  },
  headerBackground: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: "80px",
    backgroundColor: "#31373E",
    zIndex: -1,
  },
  logo: {
    width: "240px",
    height: "32px",
  },
  navbar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px",
    gap: "60px",
    width: "100%",
    height: "20px",
  },
  navLink: {
    fontFamily: "Rubik",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "20px",
    letterSpacing: "0.001em",
    color: "#B3BDC7",
    "&:hover": {
      color: "#FFFFFF",
      cursor: "pointer",
    },
  },
  link: {
    textDecoration: "none",
  },
};
