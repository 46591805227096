import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Box, Button, Link, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

interface FooterProps {
  scrollToWhoWeAre?: () => void;
  scrollToContact?: () => void;
  scrollToServices?: () => void;
  service?: boolean;
}

const Footer = ({
  scrollToWhoWeAre,
  scrollToContact,
  scrollToServices,
  service,
}: FooterProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const mobileSize = useMediaQuery("(max-width: 1130px)");
  const currentYear = new Date().getFullYear();

  const navigateToHome = () => {
    navigate("/", { state: "whoWeAre" });
  };

  const navigateToHomeServices = () => {
    navigate("/", { state: "services" });
  };

  const toTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <Box sx={styles.footer}>
      {mobileSize ? (
        <img
          style={{ position: "absolute", left: "calc(50% - 375px/2)" }}
          src="/img/footer/smallMap.png"
          alt=""
        />
      ) : null}

      <Box
        sx={{
          ...styles.map,
          backgroundImage: mobileSize ? "none" : styles.map.backgroundImage,
        }}
      >
        <Box sx={styles.footerContent}>
          <Box
            sx={{
              ...styles.background,
              padding: mobileSize ? "0px 24px" : styles.background.padding,
            }}
          >
            <Box sx={mobileSize ? styles.bgLineMobile : styles.bgLine} />
            <Box sx={mobileSize ? styles.bgLineMobile : styles.bgLine} />
            <Box sx={mobileSize ? styles.bgLineMobile : styles.bgLine} />
            {!mobileSize ? (
              <>
                <Box sx={styles.bgLine} />
                <Box sx={styles.bgLine} />
              </>
            ) : null}

            <Box sx={styles.backgroundLight} />
          </Box>
          <Box sx={styles.topButtonBox}>
            <Button onClick={toTop} sx={styles.topButton}>
              <img style={styles.upArrow} src="/img/downArrow.svg" alt="" />
            </Button>
          </Box>
          <Box
            sx={
              mobileSize
                ? styles.footerTextContainerMobile
                : styles.footerTextContainer
            }
          >
            <Typography
              sx={mobileSize ? styles.footerTextH2mobile : styles.footerTextH2}
              variant="h2"
            >
              {t("footer.hereWeAre")}
            </Typography>
            {mobileSize ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  style={styles.mapMarkerMobile}
                  src="/img/footer/mapMarker.svg"
                  alt=""
                />

                <img
                  style={styles.doubleArrowMobile}
                  src="/img/footer/verticalDoubleArrow.svg"
                  alt=""
                />
                <Box sx={styles.addressBox}>
                  <Typography sx={styles.addressH5mobile} variant="subtitle1">
                    {t("footer.officeAddress")}
                  </Typography>
                  <Typography sx={styles.addressH4mobile} variant="subtitle2">
                    {t("footer.address")}
                  </Typography>
                </Box>
              </Box>
            ) : (
              <Box sx={styles.addressBox}>
                <Typography sx={styles.addressH5} variant="h5">
                  {t("footer.officeAddress")}
                </Typography>
                <Typography sx={styles.addressH4} variant="h4">
                  {t("footer.address")}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              ...styles.footerMenu,
              padding: mobileSize ? "0px 24px" : styles.footerMenu.padding,
            }}
          >
            <Box sx={styles.navigation}>
              <Link
                onClick={service ? navigateToHomeServices : scrollToServices}
                sx={styles.navLink}
              >
                <Typography sx={styles.navLinkText} variant="subtitle2">
                  {t("common.navigation.services")}
                </Typography>
              </Link>
              <Link
                onClick={service ? navigateToHome : scrollToWhoWeAre}
                sx={styles.navLink}
              >
                <Typography sx={styles.navLinkText} variant="subtitle2">
                  {t("common.navigation.aboutUs")}
                </Typography>
              </Link>
              <Link onClick={scrollToContact} sx={styles.navLink}>
                <Typography sx={styles.navLinkText} variant="subtitle2">
                  {t("common.navigation.contact")}
                </Typography>
              </Link>
            </Box>
            <Typography sx={styles.copyright}>
              © RTS Product Engineering {currentYear}
            </Typography>
          </Box>
        </Box>

        {!mobileSize ? (
          <>
            <img
              style={styles.mapMarker}
              src="/img/footer/mapMarker.svg"
              alt=""
            />
            <img
              style={styles.doubleArrow}
              src="/img/footer/doubleArrow.svg"
              alt=""
            />
          </>
        ) : null}
      </Box>
    </Box>
  );
};

export default Footer;
