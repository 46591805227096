import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { Box, Typography, IconButton } from "@mui/material";

import { styles } from "./styles";

interface SidebarMenuItemProps {
  service: string;
  color: string;
  handleOpenSubSidebar: (service: string) => void;
}

const SidebarMenuItem = ({
  service,
  color,
  handleOpenSubSidebar,
}: SidebarMenuItemProps) => {
  const { t } = useTranslation();

  return (
    <Box sx={styles.sidebar.subMenuListItem}>
      <Link style={styles.sidebar.link} to={`/${service}`}>
        <Typography
          variant="h3"
          sx={{
            ...styles.sidebar.subMenuListText,
            color,
          }}
        >
          {t(`common.navigation.${service}`)}
        </Typography>
      </Link>
      <IconButton
        onClick={() => handleOpenSubSidebar(`${service}`)}
        sx={styles.sidebar.subSidebarOpenButton}
      >
        <img
          style={styles.sidebar.rightArrow}
          src="/img/downArrow.svg"
          alt=""
        />
      </IconButton>
    </Box>
  );
};

export default SidebarMenuItem;
