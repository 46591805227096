import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import Badge from "../Badge/Badge";

import { Box, Typography, useMediaQuery } from "@mui/material";

import { styles } from "./styles";

import grants from "./grantsText.json";
import GrantItem from "./GrantItem";

const GrantsPage = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const mobileSize = useMediaQuery("(max-width: 1130px)");
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  return (
    <Box sx={styles.grantsPage}>
      <Header handleOpenSidebar={handleOpenSidebar} />
      <Sidebar open={isSidebarOpen} handleCloseSidebar={handleCloseSidebar} />
      <Box sx={mobileSize ? styles.grantsContentMobile : styles.grantsContent}>
        <Typography sx={styles.grantsTitle} variant="h2">
          {t("common.navigation.grants")}
        </Typography>
        {grants.map((grant) => (
          <GrantItem
            key={grant.title}
            title={grant.title}
            text={grant.text}
            imgPath={grant.imgPath}
            mobileSize={mobileSize}
          />
        ))}
      </Box>
      <Badge />
    </Box>
  );
};

export default GrantsPage;
