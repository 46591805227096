import React, { useRef, useEffect, useState, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, Slide } from "react-toastify";

import { Box, Button, Typography, useMediaQuery } from "@mui/material";

import ServicesGrid from "../ServicesGrid/ServicesGrid";
import Partners from "../Partners/Partners";
import WhoWeAre from "../WhoWeAre/WhoWeAre";
import Contact from "../Contact/Contact";
import Testimonials from "../Testimonials/Testimonials";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";

import { styles } from "./styles";
import "react-toastify/dist/ReactToastify.css";
import Badge from "../Badge/Badge";

const LandingPage = () => {
  const whoWeAreRef = useRef<null | HTMLDivElement>(null);
  const contactRef = useRef<null | HTMLDivElement>(null);
  const servicesRef = useRef<null | HTMLDivElement>(null);
  const { state } = useLocation();
  const { t } = useTranslation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const mobileSize = useMediaQuery("(max-width: 1130px)");

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleOpenSidebar = () => {
    setIsSidebarOpen(true);
  };

  const scrollToWhoWeAre = useCallback(() => {
    if (whoWeAreRef.current) {
      whoWeAreRef.current.scrollIntoView({ behavior: "smooth" });
      handleCloseSidebar();
    }
  }, []);

  const scrollToContact = useCallback(() => {
    if (contactRef.current) {
      contactRef.current.scrollIntoView({ behavior: "smooth" });
      handleCloseSidebar();
    }
  }, []);

  const scrollToServices = useCallback(() => {
    if (servicesRef.current) {
      servicesRef.current.scrollIntoView({
        behavior: "smooth",
        block: mobileSize ? "start" : "center",
        inline: "nearest",
      });
      handleCloseSidebar();
    }
  }, [mobileSize]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (state === "whoWeAre") {
      scrollToWhoWeAre();
    }
    if (state === "services") {
      scrollToServices();
    }
    if (state === "contact") {
      scrollToContact();
    }
  }, [scrollToContact, scrollToServices, scrollToWhoWeAre, state]);

  return (
    <Box sx={styles.landingPage}>
      <Header
        scrollToWhoWeAre={scrollToWhoWeAre}
        scrollToContact={scrollToContact}
        handleOpenSidebar={handleOpenSidebar}
      />
      <Sidebar
        scrollToServices={scrollToServices}
        scrollToWhoWeAre={scrollToWhoWeAre}
        scrollToContact={scrollToContact}
        open={isSidebarOpen}
        handleCloseSidebar={handleCloseSidebar}
      />
      <Box sx={styles.heroServices}>
        <Box sx={styles.background}>
          <img
            style={mobileSize ? styles.closeTagMobile : styles.closeTag}
            src="/img/closeTag.svg"
            alt="closeTag"
          />
          {!mobileSize ? (
            <img
              style={styles.doubleArrow}
              src="/img/doubleArrow.svg"
              alt="doubleArrow"
            />
          ) : null}

          {!mobileSize ? (
            <>
              <Box sx={styles.line2} />
              <Box sx={styles.line6} />
              <Box sx={styles.line3} />
              <Box sx={styles.line8} />
              <Box sx={styles.line4} />
              <Box sx={styles.line5} />
            </>
          ) : null}

          <Box sx={styles.backgroundCircle} />
        </Box>
        <Box
          sx={{
            ...styles.titleTextContainer,
            paddingTop: mobileSize
              ? "150px"
              : styles.titleTextContainer.paddingTop,
            paddingLeft: mobileSize ? "24px" : "0",
          }}
        >
          <Typography variant="h1" sx={styles.titleText}>
            <span style={styles.titleTextOutlined}>
              {t("landingPage.title1")}
            </span>{" "}
            {t("landingPage.title2")}{" "}
            <span style={styles.titleTextYellow}>
              {t("landingPage.title3")}
            </span>
          </Typography>
          <Box sx={styles.titleSubtextContainer}>
            <Typography variant="h3" sx={styles.titleSubtext}>
              {t("landingPage.subTitle")}
            </Typography>
            <Button onClick={scrollToServices} sx={styles.servicesButton}>
              <Typography sx={styles.servicesButtonText}>
                {t("landingPage.servicesButton")}
              </Typography>
              <img src="/img/downArrow.svg" alt="downArrow" />
            </Button>
          </Box>
        </Box>
        <ServicesGrid ref={servicesRef} />
      </Box>
      <Partners />
      <WhoWeAre ref={whoWeAreRef} />
      <Contact
        ref={contactRef}
        bgColor="#7696FF"
        textColor="#FFFFFF"
        buttonColor="#FFB800"
      />
      <Testimonials />
      <Footer
        scrollToServices={scrollToServices}
        scrollToWhoWeAre={scrollToWhoWeAre}
        scrollToContact={scrollToContact}
      />
      <Badge />
      <ToastContainer transition={Slide} />
    </Box>
  );
};

export default LandingPage;
