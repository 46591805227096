import React from "react";
import { Link } from "react-router-dom";

import { Box, IconButton, Typography } from "@mui/material";

import { styles } from "./styles";
import { useTranslation } from "react-i18next";

interface SubSidebarMenuProps {
  handleCloseSubSidebar: () => void;
  handleCloseAllSidebar: () => void;
  service: string;
}

const SubSidebarMenu = ({
  service,
  handleCloseAllSidebar,
  handleCloseSubSidebar,
}: SubSidebarMenuProps) => {
  const { t } = useTranslation();

  const menuItems = [];
  for (let i = 1; i <= 5; i++) {
    menuItems.push(
      <Link
        style={styles.sidebar.link}
        onClick={handleCloseAllSidebar}
        to={`/${service}?id=${i}`}
      >
        <Typography
          variant="h3"
          sx={{
            ...styles.subSidebar.subMenuText,
            color: service === "software" ? "#FFFFFF" : "#222425",
          }}
        >
          {t(`servicesGrid.${service}.0${i}`)}
        </Typography>
      </Link>
    );
  }

  return (
    <>
      <Box sx={styles.subSidebar.navBox}>
        <Box sx={styles.subSidebar.navItem}>
          <IconButton
            onClick={handleCloseSubSidebar}
            sx={styles.sidebar.subSidebarOpenButton}
          >
            <img
              style={styles.subSidebar.leftArrow}
              src="/img/downArrow.svg"
              alt=""
            />
          </IconButton>
          <Typography
            variant="h3"
            sx={{
              ...styles.subSidebar.navItemText,
              color: service === "software" ? "#FFFFFF" : "#222425",
            }}
          >
            {t(`common.navigation.${service}`)}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={
          service === "software"
            ? styles.subSidebar.separatorLight
            : styles.subSidebar.separator
        }
      />
      <Box sx={styles.subSidebar.subMenuList}>{menuItems}</Box>
    </>
  );
};

export default SubSidebarMenu;
