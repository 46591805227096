export const styles = {
  services: {
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "0px calc((100% - 1110px)/2) 16px",
    isolation: "isolate",
    width: "100%",
  },
  scrollContainer: {
    marginTop: "88px",
    scrollMargin: "80px",
  },
};
