import React from "react";
import { useTranslation } from "react-i18next";

import { Box, Typography, useMediaQuery } from "@mui/material";

const ServicePageTopContent = ({
  service,
  styles,
}: {
  service?: string;
  styles: any;
}) => {
  const mobileSize = useMediaQuery("(max-width: 1130px)");
  const { t } = useTranslation();
  return (
    <Box sx={styles.heroServices}>
      <Box sx={styles.background}>
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.bgLine} />
        <Box sx={styles.backgroundLight} />
      </Box>
      <Box sx={mobileSize ? styles.titleMobile : styles.title}>
        <Box
          sx={{
            ...styles.titleText,
            padding: mobileSize ? "0px 24px" : styles.titleText.padding,
          }}
        >
          <Typography
            sx={mobileSize ? styles.titleTextH2mobile : styles.titleTextH2}
            variant="h1"
          >
            {t(`servicesGrid.${service}.title`)}
          </Typography>
          {mobileSize ? (
            <Typography sx={styles.titleDescriptionText}>
              {t(`${service}.description`)} {t(`${service}.description2`)}
            </Typography>
          ) : (
            <Box sx={styles.titleDescription}>
              <Typography sx={styles.titleDescriptionText}>
                {t(`${service}.description`)}
              </Typography>
              <Typography sx={styles.titleDescriptionText}>
                {t(`${service}.description2`)}
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ ...styles.titleLogo, left: mobileSize ? "24px" : "" }}>
          <img src={`/img/${service}PageLogos/titleLogo.png`} alt="" />
          <img
            style={styles.bigArrow}
            src={`/img/${service}PageLogos/bigArrow.svg`}
            alt=""
          />
          <img
            style={styles.mediumArrow}
            src={`/img/${service}PageLogos/mediumArrow.svg`}
            alt=""
          />
          <img
            style={styles.smallArrow}
            src={`/img/${service}PageLogos/smallArrow.svg`}
            alt=""
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ServicePageTopContent;
